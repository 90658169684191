import { forwardRef } from 'react'

import classnames from 'classnames'

import styles from './Card.module.scss'

export const Card = forwardRef(
  (
    {
      children,
      customClass,
      size,
      direction,
      align,
      withBorder,
      withShadow,
      bgColor,
      ...props
    },
    ref
  ) => {
    const cardClasses = classnames(styles.card, {
      [styles[`card--${size}`]]: size,
      [styles[`card--${direction}`]]: direction,
      [styles[`card--${align}`]]: align,
      [styles[`card--${bgColor}`]]: bgColor,
      [styles[`card--withBorder`]]: withBorder,
      [styles[`card--withShadow`]]: withShadow,
      [`${customClass}`]: customClass
    })

    return (
      <div className={cardClasses} ref={ref} {...props}>
        {children}
      </div>
    )
  }
)
