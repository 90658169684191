import { Icon } from '..'

import * as styles from './HighlightTitle.module.scss'

export const HighlightTitle = ({
  icon,
  text,
  size = 'medium',
  customClass
}) => {
  const iconSizes = {
    small: 'xs',
    medium: 'small',
    large: 'small'
  }

  return (
    <div
      className={`${styles.highlightTitle} ${styles[`highlightTitle--${size}`]} ${customClass}`}
    >
      {icon && <Icon name={icon} size={iconSizes[size]} color="primary" />}
      <p>{text}</p>
    </div>
  )
}
