import { POSITIVE_CONSUMER_URL, RIGHT_DEAL_URL } from '@/constants/links'
import { CONSUMER_PHONE_NUMBER, SAC_PHONE_NUMBER } from '@/constants/phones'

export const useGuideline = ({ closeModal }) => {
  const defaultDetails = [
    <>
      Quer contestar ou <b>negociar débitos no seu CNPJ</b>? Procure a empresa
      que realizou a negativação.
    </>,
    <>
      Quer contestar ou <b>negociar débitos no seu CPF</b>?{' '}
      <a
        target="_blank"
        rel="noreferrer"
        id="paywall-negotiateDebts-link--url"
        href={`${RIGHT_DEAL_URL}&utm_placement=paywall`}
        onClick={closeModal}
      >
        Clique aqui
      </a>
      {' ou ligue '}
      <a
        target="_blank"
        rel="noreferrer"
        id="paywall-negotiateDebts-link--phone"
        href={`tel:${CONSUMER_PHONE_NUMBER}`}
        onClick={closeModal}
      >
        {CONSUMER_PHONE_NUMBER}
      </a>
    </>,
    <>
      Não tem CNPJ e quer <b>consultar seu CPF</b>?{' '}
      <a
        target="_blank"
        rel="noreferrer"
        id="paywall-positiveConsumer-link"
        href={`${POSITIVE_CONSUMER_URL}&utm_placement=paywall`}
        onClick={closeModal}
      >
        Clique aqui
      </a>
    </>,
    <>
      Já é cliente? Fale com o SAC:{' '}
      <a
        target="_blank"
        rel="noreferrer"
        id="paywall-sac-link"
        href={`tel:${SAC_PHONE_NUMBER}`}
        onClick={closeModal}
      >
        {SAC_PHONE_NUMBER}
      </a>
    </>
  ]

  return { defaultDetails }
}
