import { Container } from '@/components'

import { NavigationButtons, NavigationLinks } from '..'

import styles from './SubHeader.module.scss'

export const SubHeader = ({ selectedCategory, closeMenu }) => {
  return (
    <Container classname={styles.subheader}>
      <NavigationLinks
        variant="subheader"
        category={selectedCategory}
        onClick={closeMenu}
      />

      <div className={styles.subheader__buttons}>
        <NavigationButtons closeMenu={closeMenu} />
      </div>
    </Container>
  )
}
