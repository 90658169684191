import { useEffect, useState } from 'react'

import { useProposalContext } from '@/context/ProposalContext'

export const useProposal = () => {
  const [hasError, setHasError] = useState(false)

  const { isLoading, setIsLoading } = useProposalContext()

  useEffect(() => setIsLoading(false), [])

  const onCatch = () => {
    setHasError(true)
    setIsLoading(false)
  }

  return {
    isLoading,
    hasError,
    setHasError,
    onCatch
  }
}
