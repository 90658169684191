import { Icon } from '@/components'

import styles from './Filters.module.scss'

export const Filters = ({ filters, activeFilter, onClick }) => {
  return (
    <div className={styles.filters}>
      <div className={styles.filters__content}>
        <ul>
          {filters.map(({ value, label }) => (
            <li key={value}>
              <button
                id={`businessSolutions-${value}Filter-button`}
                className={`${styles.filters__button} ${activeFilter.value === value && styles['filters__button--active']}`}
                onClick={() => onClick({ value, label })}
              >
                {label} <Icon name="equifax-arrow-right" size="small" />
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
