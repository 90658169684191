'use client'

import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import removeSpecialChars from '@equifax-ui/utils/formatters/removeSpecialChars'

import { axiosExternalInstance } from '@/services/axios'

import { newRelicNotifyError } from '@/utils/trackingNewRelic'
import _validators from '@/utils/validators'

import { useProposalContext } from '@/context/ProposalContext'

import { useAnalytics } from '@/hooks/useAnalytics'

import { SOFTLEAD_LABEL } from '@/constants'

export const usePaywall = () => {
  const [guidelineType, setGuidelineType] = useState('valid')
  const [title, setTitle] = useState(
    'Para continuar informe o CNPJ da sua empresa'
  )

  const {
    control,
    getValues,
    setValue,
    formState: { isValid }
  } = useForm({
    mode: 'onChange'
  })

  const { setIsPaywallOpen } = useProposalContext()
  const { getTrackingData } = useAnalytics()

  useEffect(() => {
    const softleadData = sessionStorage.getItem(SOFTLEAD_LABEL)
    const parsedSoftleadData = JSON.parse(softleadData) || {}

    const document = parsedSoftleadData.cnpj || ''

    setValue('cnpj', document, { shouldValidate: document })
  }, [])

  const closeModal = () => {
    const document = getValues().cnpj

    if (document && _validators.cnpj(document)) {
      const softleadData = sessionStorage.getItem(SOFTLEAD_LABEL)
      const parsedSoftleadData = JSON.parse(softleadData) || {}

      const newSoftleadData = { ...parsedSoftleadData, cnpj: document }

      sessionStorage.setItem(SOFTLEAD_LABEL, JSON.stringify(newSoftleadData))
    }

    setIsPaywallOpen(false)
  }

  const onClickWhatsapp = async () => {
    const apiBaseRoute = `${api.default.baseUrl}/${api.default.version}` // eslint-disable-line no-undef

    const requestConfig = {
      headers: { 'app-key': api.default.secondaryAppKey } // eslint-disable-line no-undef
    }

    const payload = {
      cnpj: removeSpecialChars(getValues().cnpj),
      analytics: await getTrackingData()
    }

    try {
      const {
        data: { lead_type }
      } = await axiosExternalInstance.post(
        `${apiBaseRoute}/softlead`,
        payload,
        requestConfig
      )

      if (lead_type === 'valid') {
        closeModal()
        return
      }

      setTitle('CNPJ verificado!')
      setGuidelineType(lead_type)

      const shouldGoToWhatsapp = false

      return shouldGoToWhatsapp
    } catch (error) {
      newRelicNotifyError(`Error getLeadType: ${error}`)
    }
  }

  const onChangeGuideline = (guidelineType) => {
    if (guidelineType === 'valid') {
      setTitle('Para continuar informe o CNPJ da sua empresa')
    }

    setGuidelineType(guidelineType)
  }

  return {
    control,
    title,
    guidelineType,
    isValid,
    onClickWhatsapp,
    onChangeGuideline,
    closeModal
  }
}
