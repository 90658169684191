import styles from './styles.module.scss'

const Radio = ({ name, label, value, checked, customClass, onChange }) => {
  return (
    <fieldset className={`${styles.radio} ${customClass}`}>
      <input
        type="radio"
        name={name}
        value={value}
        checked={checked}
        id={`${name}-${value}`}
        onChange={onChange}
        className={styles.radio__input}
      />

      <label htmlFor={`${name}-${value}`} className={styles.radio__label}>
        {label}
      </label>
    </fieldset>
  )
}

export default Radio
