import { useState } from 'react'

export const useCustomPlan = () => {
  const [isLoading, setIsLoading] = useState()

  const isOrganic = NEXT_PUBLIC_BUILD_TYPE === 'organic' // eslint-disable-line no-undef

  const planBaseRoute = isOrganic
    ? '/planos/formulario/dados-basicos/'
    : '/formulario/dados-basicos/'

  const customPlanUrlProps = {
    tagName: 'a',
    href: `${planBaseRoute}?plano=personalizado`
  }

  return {
    customPlanUrlProps,
    isLoading,
    setIsLoading
  }
}
