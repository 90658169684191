'use client'

import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { removeWhiteSpaces } from '@equifax-ui/utils/formatters/removeWhiteSpaces'
import removeSpecialChars from '@equifax-ui/utils/formatters/removeSpecialChars'

import _validators from '@/utils/validators'

import { useProposalContext } from '@/context/ProposalContext'

import { useStepHandler } from '@/hooks/useStepHandler'
import { triggerTracrTrack } from '@/hooks/useTracking'

import { API_FLOW_LABEL, SOFTLEAD_LABEL } from '@/constants'
import { E2E_FLOW_DATA } from '@/constants/steps'

export const useSoftlead = ({ basePayload, onCatch }) => {
  const { leadData, isLoading, setIsLoading } = useProposalContext()

  const { createSoftlead } = useStepHandler('softlead')

  const {
    control,
    formState: { isValid },
    getValues,
    handleSubmit,
    reset
  } = useForm({
    mode: 'onChange'
  })

  useEffect(() => {
    reset(leadData)
    setIsLoading(false)
  }, [leadData])

  useEffect(() => {
    const parsedSoftleadData =
      JSON.parse(sessionStorage.getItem(SOFTLEAD_LABEL)) || {}

    reset({ ...getValues(), ...parsedSoftleadData })
  }, [])

  const onBlurField = ({ target: { name, value } }) => {
    if (_validators[name](value)) {
      const softleadData = sessionStorage.getItem(SOFTLEAD_LABEL)
      const parsedSoftleadData = JSON.parse(softleadData) || {}

      const newSoftleadData = { ...parsedSoftleadData, [name]: value }

      sessionStorage.setItem(SOFTLEAD_LABEL, JSON.stringify(newSoftleadData))

      if (name === 'email') triggerTracrTrack('formInteracted', getValues())
    }
  }

  const onSubmit = async (data) => {
    const payload = {
      ...data,
      ...basePayload,
      cnpj: removeSpecialChars(data.cnpj),
      phone: removeWhiteSpaces(removeSpecialChars(data.phone))
    }

    sessionStorage.removeItem(API_FLOW_LABEL)

    await createSoftlead({ payload, flowData: E2E_FLOW_DATA, onCatch })
  }

  return {
    control,
    isValid,
    isLoading,
    onBlurField,
    handleSubmit,
    onSubmit
  }
}
