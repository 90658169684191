'use client'

import { useState } from 'react'

import { usePlans } from '@/hooks/usePlans'

import { If, Modal, PaymentExample, Plan } from '@/components'

import { RECOMMENDED_PLAN_PRICE } from '@/constants/plans'

import styles from './PlanList.module.scss'

export const PlanList = ({
  children,
  customClass,
  customPlans,
  selectedPlan,
  setSelectedPlan,
  hasCTA
}) => {
  const [isPaymentDetailsOpen, setIsPaymentDetailsOpen] = useState(false)
  const [expandedPlans, setExpandedPlans] = useState({})

  const { postpaidPlans } = usePlans()

  const plans = customPlans || postpaidPlans

  return (
    <>
      <div
        className={`
          ${styles.plans} 
          ${customPlans && styles['plans--custom']} 
          ${customClass}
        `}
      >
        {plans.map((plan) => (
          <Plan
            plan={plan}
            key={`plans-${plan.plan_price}`}
            selectedPlan={selectedPlan || RECOMMENDED_PLAN_PRICE}
            customClass={`${plan.is_affiliate && styles['plans__card--affiliate']}`}
            expandedPlans={expandedPlans}
            setExpandedPlans={setExpandedPlans}
            openPaymentDetails={() => setIsPaymentDetailsOpen(true)}
            onClickCard={setSelectedPlan}
            hasCTA={hasCTA}
          >
            {children}
          </Plan>
        ))}
      </div>

      <If condition={isPaymentDetailsOpen}>
        <Modal
          size="medium"
          section="plans"
          onClose={() => setIsPaymentDetailsOpen(false)}
        >
          <PaymentExample />
        </Modal>
      </If>
    </>
  )
}
