import { useEffect, useState } from 'react'

import isClient from '@equifax-ui/utils/browser/isClient'

import { SOURCE_URL_LABEL } from '@/constants/api'

export const useConditionalRendering = () => {
  const [renderedContentType, setRenderedContentType] = useState()

  const sourceURL =
    isClient() && JSON.parse(sessionStorage.getItem(SOURCE_URL_LABEL))

  useEffect(() => {
    if (!sourceURL || renderedContentType) return

    if (sourceURL.includes('r2n')) {
      setRenderedContentType('r2n')
      return
    }

    setRenderedContentType('default')
  }, [sourceURL, renderedContentType])

  return { sourceURL, renderedContentType }
}
