import classNames from 'classnames'

import { Divider, Icon } from '@/components'

import { INTERCONNECT_BOX_DETAILS } from '@/constants/plans'

import { UnitValues } from '..'

import styles from './Details.module.scss'

export const Details = ({ plan, isExpanded, openPaymentDetails }) => {
  const {
    negativation_unit_value: { electronic_notice, negativation }
  } = plan

  return (
    <>
      <div
        className={classNames(styles.details, {
          [styles['details--expanded']]: isExpanded
        })}
      >
        <div className={styles.details__content}>
          <p className={styles.details__title}>Produtos disponíveis</p>

          <UnitValues
            unitValuesLabel="Acerta"
            label="Consulta por CPF"
            unitValues={plan.acerta_unit_value}
          />

          <Divider />

          <UnitValues
            unitValuesLabel="Define"
            label="Consulta por CNPJ"
            unitValues={plan.define_unit_value}
          />

          <Divider />

          <UnitValues
            label="Notificação e Negativação"
            unitValues={{
              'Aviso eletrônico de débito': electronic_notice,
              'Carta + Negativação': negativation
            }}
          />

          <Divider />

          <UnitValues
            label="InterConnect Box Aprova"
            unitValues={INTERCONNECT_BOX_DETAILS}
          />
        </div>

        <button
          type="button"
          className={styles.details__button}
          onClick={openPaymentDetails}
        >
          Entenda o pagamento
          <Icon name="equifax-info" size="xs" />
        </button>
      </div>
    </>
  )
}
