'use client'

import { Container, Icon, Modal } from '@/components'
import SelectSearch from '@/components/Form/Vanilla/Select'

import { Filters, Heading, PlanDetails } from '.'

import { useServicesModal } from './useServicesModal'

import styles from './ServicesModal.module.scss'

export const ServicesModal = ({ type, closeModal }) => {
  const { header, activeFilter, dataToRender, setActiveFilter } =
    useServicesModal({ type })

  return (
    <Modal
      section="businessSolutions"
      customClass={styles.plansInfo}
      onClose={closeModal}
    >
      <Container classname={styles.plansInfo__wrapper}>
        <Heading tabInfos={dataToRender.tabInfos} data={header} />

        <div className={styles.plansInfo__subtitles}>
          {dataToRender.subtitles.map(({ icon, icon_color, label }) => (
            <label
              key={`subtitle-${label}`}
              className={styles.plansInfo__subtitle}
            >
              <Icon size="small" name={icon} color={icon_color} />
              {label}
            </label>
          ))}
        </div>

        <div className={styles.plansInfo__filteredDetails}>
          <div className={styles['plansInfo__filters--desktop']}>
            <Filters
              filters={dataToRender.filters}
              activeFilter={activeFilter}
              onClick={setActiveFilter}
            />
          </div>
          <div className={styles['plansInfo__filters--mobile']}>
            <SelectSearch
              id="businessSolutions-filter"
              name="businessSolutions-filter"
              label="Informações:"
              options={dataToRender.filters}
              defaultValue={dataToRender.filters[0].label}
              onChange={(_, filter) => setActiveFilter(filter)}
            />
          </div>

          <PlanDetails
            tableHeader={dataToRender.tableHeader}
            tableContent={dataToRender.tableContent[activeFilter.value]}
          />
        </div>
      </Container>
    </Modal>
  )
}
