const toCnpj = (cnpj) => {
  return cnpj?.replace(
    /^([0-9]{2})([0-9]{3})([0-9]{3})([0-9]{4})([0-9]{2})$/,
    '$1.$2.$3/$4-$5'
  )
}

const toZipcode = (zipcode) => {
  return zipcode?.replace(/^([0-9]{5})([0-9]{3})$/, '$1-$2')
}

const removeMultipleSpaces = (str) => {
  return str?.replace(/\s+/g, ' ').trim()
}

const toCurrency = (value) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  })
    .format(value)
    .replace(/\u00A0/g, ' ')
}

export default {
  toCnpj,
  toZipcode,
  toCurrency,
  removeMultipleSpaces
}
