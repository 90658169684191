'use client'

import { useState, useEffect } from 'react'

import { useProposalContext } from '@/context/ProposalContext'

import { usePlans } from '@/hooks/usePlans'

import { If, Icon } from '@/components'

import _formatters from '@/utils/formatters'

import styles from './ShoppingCart.module.scss'

export const ShoppingCart = () => {
  const [plan, setPlan] = useState()
  const [isCartOpen, setIsCartOpen] = useState(false)

  const { leadData, isFeedbackStep } = useProposalContext()

  const { postpaidPlans } = usePlans()

  useEffect(() => {
    if (leadData?.plan) {
      const plan = postpaidPlans.find(
        ({ plan_price }) => plan_price === leadData?.plan_price
      )

      setPlan(plan)
    }
  }, [leadData])

  return (
    <If condition={plan && !isFeedbackStep}>
      <button
        id="formHeader-shoppingCart-button"
        aria-label="Carrinho de compras"
        onClick={() => setIsCartOpen(!isCartOpen)}
      >
        <Icon name="equifax-full-shopping-cart" size="small" />
      </button>

      <If condition={isCartOpen}>
        <div className={styles.plan}>
          <p className={styles.plan__name}>Plano {plan?.name}</p>
          <p className={styles.plan__price}>
            <b>{_formatters.toCurrency(plan?.plan_price)}</b> por mês
          </p>
        </div>
      </If>
    </If>
  )
}
