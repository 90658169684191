'use client'

import { useState } from 'react'

import useMediaQuery from '@equifax-ui/hooks/useMediaQuery'

import { Icon, Tooltip } from '@/components'

import styles from './TooltipHint.module.scss'

export const TooltipHint = ({ id, text }) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  const isMobile = useMediaQuery('screen and (max-width: 540px)')

  return (
    <button
      id={id}
      type="button"
      className={`${styles.tooltipHint} ${isTooltipOpen && styles['tooltipHint--active']}`}
      onClick={(e) => {
        e.preventDefault()
        setIsTooltipOpen((prev) => !prev)
      }}
    >
      <Icon
        size="xs"
        name="equifax-info"
        className={styles.tooltipHint__icon}
      />

      <Tooltip
        text={text}
        position={isMobile ? 'left' : 'top'}
        customClass={styles.tooltipHint__tooltip}
      />
    </button>
  )
}
