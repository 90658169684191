import { useEffect, useState } from 'react'

import {
  AFFILIATE_PLAN,
  POST_PAID_PLANS,
  PRE_PAID_PLANS
} from '@/constants/plans'

import { useConditionalRendering } from './useConditionalRendering'

export const usePlans = () => {
  const [postpaidPlans, setPostpaidPlans] = useState(POST_PAID_PLANS)

  const { renderedContentType } = useConditionalRendering()

  useEffect(() => {
    if (!renderedContentType) return

    if (renderedContentType === 'r2n') {
      setPostpaidPlans((prev) => [...prev, AFFILIATE_PLAN])
    }
  }, [renderedContentType])

  return {
    postpaidPlans,
    prepaidPlans: PRE_PAID_PLANS
  }
}
