import { Icon, Tab } from '@/components'

import styles from './Heading.module.scss'

export const Heading = ({ tabInfos, data }) => {
  return (
    <>
      <div className={styles['heading--mobile']}>
        <Icon color="black" name={tabInfos.icon} />
        <h4 className={styles.heading__title}>{tabInfos.title}</h4>
      </div>

      <div className={styles['heading--desktop']}>
        <Tab header={data} />
      </div>
    </>
  )
}
