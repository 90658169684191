import { useState } from 'react'

import useMediaQuery from '@equifax-ui/hooks/useMediaQuery'

import { Icon, If } from '@/components'

import styles from './PlanDetails.module.scss'

export const PlanDetails = ({ tableHeader, tableContent }) => {
  const [visiblePlanIndex, setVisiblePlanIndex] = useState(0)

  const isMobile = useMediaQuery('screen and (max-width: 767px)')

  const ICON_COLORS = {
    'equifax-check': 'blue',
    'equifax-close': 'error',
    'equifax-money': 'orange'
  }

  return (
    <>
      <div className={styles.planDetails__filters}>
        {tableHeader.map(({ short_title }, index) => (
          <button
            key={`${short_title}-index`}
            className={`
              ${styles.planDetails__filter} 
              ${visiblePlanIndex === index && styles['planDetails__filter--active']}
            `}
            onClick={() => setVisiblePlanIndex(index)}
          >
            {short_title}
          </button>
        ))}
      </div>

      <table className={styles.planDetails}>
        <thead className={styles.planDetails__heading}>
          <tr>
            <th />
            {tableHeader.map(({ icon, title }) => (
              <th key={`businessSolutions-modal-heading-${title}`}>
                <div className={styles.columnHeading}>
                  <Icon name={icon} size="small" color="primary" />
                  <span className={styles.columnHeading__title}>{title}</span>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableContent.map(({ text, icons }) => (
            <tr key={`businessSolutions-modal-row-${text}`}>
              <td>{text}</td>
              <If
                condition={isMobile}
                renderIf={
                  <td>
                    <Icon
                      size="small"
                      name={icons[visiblePlanIndex]}
                      color={ICON_COLORS[icons[visiblePlanIndex]]}
                    />
                  </td>
                }
                renderElse={
                  <>
                    <td>
                      <Icon
                        name={icons[0]}
                        size="small"
                        color={ICON_COLORS[icons[0]]}
                      />
                    </td>
                    <td>
                      <Icon
                        name={icons[1]}
                        size="small"
                        color={ICON_COLORS[icons[1]]}
                      />
                    </td>
                    <td>
                      <Icon
                        name={icons[2]}
                        size="small"
                        color={ICON_COLORS[icons[2]]}
                      />
                    </td>
                  </>
                }
              />
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}
