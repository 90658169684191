import styles from './List.module.scss'

export const List = ({ items }) => {
  return (
    <ul className={styles.list}>
      {items.map((option, index) => (
        <li key={`${option}-${index}`}>{option}</li>
      ))}
    </ul>
  )
}
