import { IdleValue } from 'idlize/IdleValue.mjs'
import { isCpf as validateCpf } from 'iscpf'

const cnpj = (_cnpj) => {
  if (!_cnpj) return false
  var b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]

  if ((_cnpj = _cnpj.replace(/[^\d]/g, '')).length != 14) return false

  if (/0{14}/.test(_cnpj)) return false

  for (var i = 0, n = 0; i < 12; n += _cnpj[i] * b[++i]);
  if (_cnpj[12] != ((n %= 11) < 2 ? 0 : 11 - n)) return false

  for (var j = 0, m = 0; j <= 12; m += _cnpj[j] * b[j++]);
  if (_cnpj[13] != ((m %= 11) < 2 ? 0 : 11 - m)) return false

  return true
}

const name = (name, nameCount = false) => {
  if (!name) name = ''
  const nameWithoutSpaces = name.trim(),
    blackListedNames = /^(dr|dra|sr|sra|eng)$/,
    validCharRegex =
      /^[a-záéíóúàâêôãõüçñöÁÉÍÓÚÀÂÊÔÃÕÜÇÑÖ][a-z\\'áéíóúàâêôãõüçñöÁÉÍÓÚÀÂÊÔÃÕÜÇÑÖ\s-]+$/gi,
    fullNameArr = nameWithoutSpaces.split(/\s+/),
    isValidNameCount = nameCount
      ? fullNameArr.length === nameCount
      : fullNameArr.length > 1

  if (
    isValidNameCount &&
    nameWithoutSpaces.match(validCharRegex) &&
    !nameWithoutSpaces.match(blackListedNames) &&
    fullNameArr.every((fullName) => {
      if (!fullName.match(blackListedNames)) {
        return true
      } else {
        return false
      }
    })
  ) {
    return true
  } else {
    return false
  }
}

const email = (_email = '') => {
  const regExp =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  const invalidDomains = [
    'teste.com',
    'teste.com.br',
    'gamil.com',
    'gmial.com',
    'gmaill.com',
    'gmal.com',
    'gmail.cmo',
    'gmaul.com',
    'gemail.com',
    'gmail.con',
    'gmali.com',
    'gimail.com',
    'yhaoo.com',
    'yaoho.com',
    'yaho.com',
    'yahoo.cmo',
    'yahoocom.com',
    'yhaoo.con',
    'yaoo.com',
    'yahooc.om',
    'yahho.com',
    'yaahoo.com',
    'hotamil.com',
    'hotmial.com',
    'hotmaill.com',
    'hotmal.com',
    'hotmail.cmo',
    'hotmil.com',
    'hotemail.com',
    'hotmail.con',
    'hotmali.com',
    'hotmmail.com',
    'outllok.com',
    'outook.com',
    'outlokk.com',
    'outlooc.com',
    'outloook.com',
    'outlook.cmo',
    'outlouk.com',
    'outlok.com',
    'uotlook.com',
    'otlook.com',
    'aool.com',
    'aol.con',
    'aol.cmo',
    'alo.com',
    'aoll.com',
    'aol.om',
    'aol.co',
    'aol.comm',
    'aol.ocm',
    'aol.cm'
  ]

  if (_email.indexOf('@-') > 0) {
    return false
  }

  if (!regExp.test(_email)) {
    return false
  }

  const domain = _email.split('@')[1]

  if (invalidDomains.includes(domain)) {
    return false
  }

  return true
}

const phone = (value = '', minLength = 10, maxLength = 11, type) => {
  if (!value) return false

  if (value === null) value = ''
  let onlyNums = value.match(/\d+/g)

  if (!onlyNums) {
    return false
  } else {
    onlyNums = onlyNums.join('')
  }

  if (
    /(\d)\1{7,}/.test(onlyNums.substr(2)) ||
    /^(.)\1+$/.test(onlyNums.substring(2)) ||
    /^0/.test(onlyNums) ||
    (type === 'cellphone' && !/^\d{2}[4-9]\d{8}$/.test(onlyNums)) ||
    onlyNums.length < minLength ||
    onlyNums.length > maxLength
  ) {
    return false
  } else {
    return true
  }
}
const date = (str) => {
  if (!str) {
    return false
  }

  return str.match(
    /(^(((0[1-9]|1[0-9]|2[0-8])[/](0[1-9]|1[012]))|((29|30|31)[/](0[13578]|1[02]))|((29|30)[/](0[4,6,9]|11)))[/](19|[2-9][0-9])\d\d$)|(^29[/]02[/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/g
  )
    ? true
    : false
}

const cpf = (_cpf) =>
  _cpf
    ? !_cpf.replace(/[._-]+/gi, '').match(/^(.)\1+$/) && validateCpf(_cpf)
    : true

const isClient = new IdleValue(
  () =>
    !!(
      typeof window !== 'undefined' &&
      window.document &&
      window.document.createElement
    )
)
const cep = (value) => {
  if (!value) value = ''
  const match = value.match(/\d+/gi),
    result = match ? match.join('') : null

  if (result && !/^(.)\1+$/.test(value.replace(/[\D]/, ''))) {
    return result.length === 8
  }

  return false
}

const website = (str) => {
  if (!str) {
    return false
  }

  const regex =
    /^(https?:\/\/)?([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]*)?\/?$/

  return regex.test(str)
}

export default {
  cnpj,
  name,
  email,
  phone,
  date,
  cpf,
  cep,
  website,
  isClient
}
