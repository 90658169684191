const E2E_STEPS = {
  softlead: 'dados-basicos',
  'otp-verification': 'verificacao',
  plans: 'planos',
  simulation: 'simulacao',
  'simulation-result': 'sugestoes',
  business: 'dados-empresa',
  personal: 'dados-adicionais',
  'confirmation-review': 'confirmacao',
  summary: 'conclusao',
  client: 'cliente',
  default: 'dados-recebidos',
  hunter: 'aguardando-contato',
  unavailable: 'indisponivel',
  'not-enriched': 'dados-recebidos',
  'lead-duplicated': 'contratante',
  'invalid-document': 'negado'
}

// const SELF_CONSULT_STEPS = {
//   softlead: 'consultar',
//   identity: 'identidade',
//   'mother-first-name': 'nome-mae',
//   'mother-last-name': 'sobrenome-mae',
//   'phone-number': 'telefone',
//   'birth-year': 'nascimento',
//   street: 'endereco',
//   city: 'cidade',
//   'contact-info': 'contato',
//   denied: 'invalido',
//   'no-data-available': 'sem-dados',
//   'blocked-consultation': 'consulta-bloqueada'
// }

export const STEPS = {
  'flow-post-paid-a': E2E_STEPS
  // 'self-consult': SELF_CONSULT_STEPS
}

export const E2E_FLOW_DATA = {
  flow: 'flow-post-paid-a',
  path: 'form'
}

export const SELF_CONSULT_FLOW_DATA = {
  // path: '',
  // flow: ''
}
