import Image from 'next/image'

import ScoreImg from '/public/illustrations/score.svg'
import ScorePointerImg from '/public/illustrations/score-pointer.svg'

import styles from './Score.module.scss'

export const Score = ({ scorePosition, customClass }) => {
  return (
    <div className={`${styles.score} ${customClass}`}>
      <Image
        src={ScoreImg}
        alt="roleta de score"
        style={{
          width: '100%',
          height: 'auto'
        }}
      />

      <Image
        alt="ponteiro de score"
        src={ScorePointerImg}
        className={styles.score__pointer}
        style={{
          transform: `translateX(-50%) rotate(${scorePosition}deg)`
        }}
      />
    </div>
  )
}
