'use client'

import { useState } from 'react'

import { Card, If } from '..'

import { Feedback, Grading } from './components'

export const NPS = ({ onSendNPS = () => {} }) => {
  const [isNPSSent, setIsNPSSent] = useState(false)

  return (
    <Card withBorder size="small" align="center">
      <If
        condition={isNPSSent}
        renderIf={<Feedback />}
        renderElse={
          <Grading
            onSendNPS={() => {
              setIsNPSSent(true)
              onSendNPS(true)
            }}
          />
        }
      />
    </Card>
  )
}
