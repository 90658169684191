import styles from './Tooltip.module.scss'

export const Tooltip = ({ position = 'right', text = '', customClass }) => {
  return (
    <div
      className={`${styles.tooltip} ${styles[`tooltip--${position}`]} ${customClass}`}
    >
      <p className={styles.tooltip__text}>{text}</p>
      <span
        className={`${styles.tooltip__arrow} ${styles[`tooltip__arrow--${position}`]}`}
      />
    </div>
  )
}
