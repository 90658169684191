import { newRelicNotifyError } from './trackingNewRelic'

export const setClarityPriority = async (name) => {
  try {
    await window.clarity('upgrade', name)
  } catch (err) {
    newRelicNotifyError(`Error setClarityPriority: ${err}`)
  }
}

export const setClarityFilter = async (name, value) => {
  try {
    await window.clarity('set', name, value)
  } catch (err) {
    newRelicNotifyError(`Error setClarityFilter: ${err}`)
  }
}
