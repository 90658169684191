export const ACERTA_DATA = {
  tabInfos: {
    id: 'acerta',
    section: 'servicesModal',
    icon: 'equifax-user-search',
    title: 'Consulta de CPF'
  },
  subtitles: [
    {
      icon: 'equifax-check',
      icon_color: 'blue',
      label: 'Disponível'
    },
    {
      icon: 'equifax-close',
      icon_color: 'error',
      label: 'Indisponível'
    }
  ],
  filters: [
    { value: 'cadastrais', label: 'Cadastrais' },
    { value: 'restritivos', label: 'Restritivos' },
    { value: 'comportamentais', label: 'Comportamentais' },
    { value: 'inteligencia_analitica', label: 'Inteligência Analítica' },
    { value: 'indicadores_positivos', label: 'Indicadores Positivos' }
  ],
  tableHeader: [
    {
      icon: 'equifax-user-square',
      title: 'Consulta Completa',
      short_title: 'Completa'
    },
    {
      icon: 'equifax-user-plus',
      title: 'Consulta Intermediária',
      short_title: 'Intermediária'
    },
    {
      icon: 'equifax-user-check',
      title: 'Consulta Básica',
      short_title: 'Básica'
    }
  ],
  tableContent: {
    cadastrais: [
      {
        text: 'Identificação completa',
        icons: ['equifax-check', 'equifax-check', 'equifax-check']
      },
      {
        text: 'Nº de título de eleitor',
        icons: ['equifax-check', 'equifax-check', 'equifax-check']
      },
      {
        text: 'Confirmação de telefone',
        icons: ['equifax-check', 'equifax-check', 'equifax-check']
      },
      {
        text: 'Óbitos',
        icons: ['equifax-check', 'equifax-check', 'equifax-check']
      },
      {
        text: 'Situação de CPF na RFB',
        icons: ['equifax-check', 'equifax-check', 'equifax-check']
      },
      {
        text: 'Confirmação de CEP',
        icons: ['equifax-check', 'equifax-check', 'equifax-check']
      },
      {
        text: 'Localização+Telefone ',
        icons: ['equifax-check', 'equifax-check', 'equifax-check']
      },
      {
        text: 'Melhor telefone',
        icons: ['equifax-check', 'equifax-check', 'equifax-check']
      },
      {
        text: 'Telefones vinculados',
        icons: ['equifax-check', 'equifax-check', 'equifax-close']
      },
      {
        text: 'Participação em empresas',
        icons: ['equifax-check', 'equifax-check', 'equifax-check']
      }
    ],
    restritivos: [
      {
        text: 'Registro de débitos',
        icons: ['equifax-check', 'equifax-check', 'equifax-check']
      },
      {
        text: 'Registro de débito segmentado',
        icons: ['equifax-check', 'equifax-close', 'equifax-close']
      },
      {
        text: 'Protestos',
        icons: ['equifax-check', 'equifax-check', 'equifax-check']
      },
      {
        text: 'CCF (Cheque sem fundo)',
        icons: ['equifax-check', 'equifax-check', 'equifax-check']
      },
      {
        text: 'Ações',
        icons: ['equifax-check', 'equifax-check', 'equifax-check']
      },
      {
        text: 'Falência empresarial ',
        icons: ['equifax-check', 'equifax-check', 'equifax-check']
      }
    ],
    comportamentais: [
      {
        text: 'Alerta de documentos',
        icons: ['equifax-check', 'equifax-check', 'equifax-check']
      },
      {
        text: 'Consultas anteriores',
        icons: ['equifax-check', 'equifax-check', 'equifax-check']
      },
      {
        text: 'Outras grafias',
        icons: ['equifax-check', 'equifax-check', 'equifax-check']
      },
      {
        text: 'Consultas anteriores por segmento',
        icons: ['equifax-check', 'equifax-check', 'equifax-close']
      },
      {
        text: 'Ações',
        icons: ['equifax-check', 'equifax-close', 'equifax-close']
      },
      {
        text: 'Alerta de fraude (Inconsistência PJ)',
        icons: ['equifax-check', 'equifax-close', 'equifax-close']
      }
    ],
    inteligencia_analitica: [
      {
        text: 'Modelo único de score',
        icons: ['equifax-check', 'equifax-check', 'equifax-check']
      },
      {
        text: 'Renda presumida',
        icons: ['equifax-check', 'equifax-check', 'equifax-check']
      },
      {
        text: 'Decisão',
        icons: ['equifax-check', 'equifax-check', 'equifax-close']
      },
      {
        text: 'Limite de parcelas',
        icons: ['equifax-check', 'equifax-check', 'equifax-close']
      },
      {
        text: 'Link Cheques',
        icons: ['equifax-check', 'equifax-check', 'equifax-check']
      }
    ],
    indicadores_positivos: [
      {
        text: 'Faturas em atraso',
        icons: ['equifax-check', 'equifax-check', 'equifax-check']
      },
      {
        text: 'Contratos recentes',
        icons: ['equifax-check', 'equifax-check', 'equifax-check']
      },
      {
        text: 'Pontualidade de pagamentos',
        icons: ['equifax-check', 'equifax-check', 'equifax-close']
      },
      {
        text: 'Adiantamento de pagamentos',
        icons: ['equifax-check', 'equifax-close', 'equifax-close']
      },
      {
        text: 'Grau de comprometimento',
        icons: ['equifax-check', 'equifax-close', 'equifax-close']
      }
    ]
  }
}

export const DEFINE_DATA = {
  tabInfos: {
    id: 'define',
    section: 'servicesModal',
    icon: 'equifax-building-search',
    title: 'Consulta de CNPJ'
  },
  subtitles: [
    {
      icon: 'equifax-check',
      icon_color: 'blue',
      label: 'Disponível'
    },
    {
      icon: 'equifax-money',
      icon_color: 'orange',
      label: 'Disponível com investimento adicional'
    }
  ],
  filters: [
    { value: 'cadastrais', label: 'Cadastrais' },
    { value: 'restritivos', label: 'Restritivos' },
    { value: 'inteligencia_analitica', label: 'Inteligência Analítica' },
    { value: 'indicadores_positivos', label: 'Indicadores Positivos' },
    { value: 'opcionais', label: 'Opcionais' }
  ],
  tableHeader: [
    {
      icon: 'equifax-building-square',
      title: 'Consulta Completa',
      short_title: 'Completa'
    },
    {
      icon: 'equifax-building-plus',
      title: 'Consulta Intermediária',
      short_title: 'Intermediária'
    },
    {
      icon: 'equifax-building-check',
      title: 'Consulta Básica',
      short_title: 'Básica'
    }
  ],
  tableContent: {
    cadastrais: [
      {
        text: 'Informações cadastrais',
        icons: ['equifax-check', 'equifax-check', 'equifax-check']
      },
      {
        text: 'Quadro social',
        icons: ['equifax-check', 'equifax-check', 'equifax-money']
      },
      {
        text: 'Administradores',
        icons: ['equifax-check', 'equifax-check', 'equifax-money']
      },
      {
        text: 'Participação em outras empresas',
        icons: ['equifax-check', 'equifax-check', 'equifax-money']
      },
      {
        text: 'Sócios em outras empresas',
        icons: ['equifax-check', 'equifax-check', 'equifax-money']
      },
      {
        text: 'Resumo restritivo',
        icons: ['equifax-check', 'equifax-check', 'equifax-check']
      },
      {
        text: 'Consulta',
        icons: ['equifax-check', 'equifax-check', 'equifax-check']
      }
    ],
    restritivos: [
      {
        text: 'Ações Cíveis',
        icons: ['equifax-check', 'equifax-check', 'equifax-money']
      },
      {
        text: 'Recuperação empresarial',
        icons: ['equifax-check', 'equifax-check', 'equifax-money']
      },
      {
        text: 'Falência empresarial',
        icons: ['equifax-check', 'equifax-check', 'equifax-money']
      }
    ],
    inteligencia_analitica: [
      {
        text: 'Score',
        icons: ['equifax-check', 'equifax-check', 'equifax-check']
      },
      {
        text: 'Sugestão de negócio',
        icons: ['equifax-check', 'equifax-check', 'equifax-money']
      },
      {
        text: 'Faturamento presumido',
        icons: ['equifax-check', 'equifax-money', 'equifax-money']
      },
      {
        text: 'Limite de crédito',
        icons: ['equifax-check', 'equifax-money', 'equifax-money']
      }
    ],
    indicadores_positivos: [
      {
        text: 'Pagamento Pontual / Atraso',
        icons: ['equifax-check', 'equifax-check', 'equifax-check']
      },
      {
        text: 'Crédito obtido',
        icons: ['equifax-check', 'equifax-check', 'equifax-check']
      },
      {
        text: 'Comprometimento futuro',
        icons: ['equifax-check', 'equifax-check', 'equifax-check']
      },
      {
        text: 'Relacionamento com fornecedores',
        icons: ['equifax-check', 'equifax-check', 'equifax-check']
      }
    ],
    opcionais: [
      {
        text: 'Anvisa',
        icons: ['equifax-money', 'equifax-money', 'equifax-money']
      },
      {
        text: 'Empresas no mesmo endereço',
        icons: ['equifax-money', 'equifax-money', 'equifax-money']
      },
      {
        text: 'Informações da folha de cheque',
        icons: ['equifax-money', 'equifax-money', 'equifax-money']
      },
      {
        text: 'Consulta de histórico',
        icons: ['equifax-money', 'equifax-money', 'equifax-money']
      },
      {
        text: 'Gera alerta 30 dias',
        icons: ['equifax-check', 'equifax-check', 'equifax-check']
      }
    ]
  }
}
