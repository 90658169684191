import { useRouter } from 'next/navigation'

import { createLead, getStepData, submitStep } from '@/services/steps'

import { newRelicNotifyError } from '@/utils/trackingNewRelic'
import { setClarityFilter } from '@/utils/clarity'

import { useProposalContext } from '@/context/ProposalContext'

import { API_FLOW_LABEL, TOKEN_LABEL } from '@/constants'
import { DEFAULT_ERROR_DATA, ERRORS } from '@/constants/errors'

import { E2E_FLOW_DATA, STEPS } from '@/constants/steps'

import { useAnalytics } from './useAnalytics'
import { useNavigation } from './useNavigation'

export const useStepHandler = (currentStep = 'softlead') => {
  const {
    previousStep,
    leadData,
    setIsLoading,
    setLeadData,
    setPreviousStep,
    updateToast
  } = useProposalContext()

  const router = useRouter()

  const { getTrackingData } = useAnalytics()
  const { redirectStep, goBackHome } = useNavigation()

  const clearToken = () => {
    sessionStorage.removeItem(TOKEN_LABEL)
  }

  const getFlowData = () => {
    const flowDataFromStorage = sessionStorage.getItem(API_FLOW_LABEL)
    const flowData = JSON.parse(flowDataFromStorage) || E2E_FLOW_DATA

    return flowData
  }

  const getSoftleadData = async (formData) => {
    const trackingData = await getTrackingData()

    const payload = {
      ...formData,
      analytics: trackingData
    }

    return payload
  }

  const createSoftlead = async ({ payload, flowData, onCatch = () => {} }) => {
    setIsLoading(true)

    try {
      const {
        data: { next_step = 'softlead', form_finished = false, auth, data }
      } = await createLead({
        ...flowData,
        payload: await getSoftleadData(payload)
      })

      await setClarityFilter('lead_id', data.lead_id)

      setLeadData((prev) => ({ ...prev, ...data }))

      if (auth) {
        sessionStorage.setItem(TOKEN_LABEL, JSON.stringify(auth))
      }

      if (form_finished) clearToken()

      const steps = STEPS[flowData.flow]

      router.push(`/formulario/${steps[next_step]}`)
    } catch (err) {
      onError(err?.response)
      onCatch()
    }
  }

  const getInitialValues = async (onSuccess = () => {}) => {
    setIsLoading(true)

    const tokenFromStorage = sessionStorage.getItem(TOKEN_LABEL)

    if (!tokenFromStorage) {
      await goBackHome()
      return
    }

    try {
      const flowData = getFlowData()

      const {
        data: { data = {}, previous_step, next_step, form_finished = false }
      } = await getStepData({ ...flowData, step: currentStep })

      setLeadData(data)
      setPreviousStep(previous_step)

      if (form_finished) clearToken()

      window.scroll({
        top: 0,
        behavior: 'smooth'
      })

      if ('is_document_valid' in data && !data.is_document_valid && next_step) {
        redirectStep(next_step)
        return
      }

      onSuccess(data)
      setIsLoading(false)
    } catch (err) {
      onError(err?.response)
    }
  }

  const goNextStep = async (payload) => {
    setIsLoading(true)

    try {
      const flowData = getFlowData()

      const {
        data: { next_step = 'softlead', data = leadData, form_finished = false }
      } = await submitStep({ ...flowData, step: currentStep, payload })

      setLeadData(data)

      if (form_finished) clearToken()

      redirectStep(next_step)
    } catch (err) {
      onError(err?.response)
    }
  }

  const goPreviousStep = () => {
    setIsLoading(true)

    if (previousStep) {
      redirectStep(previousStep)
      return
    }

    goBackHome()
  }

  const onError = (err) => {
    newRelicNotifyError(`Error: ${err}`)

    const httpStatusCode = err?.status
    const nextStep = err?.data?.error?.data?.next_step

    if (httpStatusCode === 401 || httpStatusCode === 404) {
      clearToken()
      goBackHome()
      return
    }

    if (currentStep === 'summary' && httpStatusCode === 504) {
      clearToken()
      setIsLoading(false)
      return
    }

    if (nextStep) {
      redirectStep(nextStep)
      return
    }

    const errorData = ERRORS[httpStatusCode] || DEFAULT_ERROR_DATA

    updateToast(errorData)
    setIsLoading(false)
  }

  return {
    clearToken,
    createSoftlead,
    getInitialValues,
    goPreviousStep,
    goNextStep,
    goBackHome
  }
}
