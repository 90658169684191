/* eslint-disable no-undef */

export const TOKEN_LABEL = 'bvs-token'
export const DOCUMENT_LABEL = 'bvs-document'
export const SOFTLEAD_LABEL = 'bvs-softlead'
export const ONBOARDING_LABEL = 'bvs-onboarding'
export const API_FLOW_LABEL = 'bvs-flow'

export const env =
  process.env.BUILD_ENV || process.env.NODE_ENV || 'development'

export const IS_PRODUCTION = env === 'production'
