'use client'

import { Button, ButtonWhatsapp, List } from '..'

import { CUSTOM_PLAN } from '@/constants/plans'

import { useCustomPlan } from './useCustomPlan'

import styles from './CustomPlan.module.scss'

export const CustomPlan = ({
  children,
  text,
  details,
  reference,
  customClass,
  variant,
  whatsappVariant = 'secondary',
  hasCustomButton = true,
  hasWhatsappButton = true,
  onClickCTA
}) => {
  const { customPlanUrlProps, isLoading, setIsLoading } = useCustomPlan()

  return (
    <>
      <div
        ref={reference}
        className={`
          ${styles.plan}
          ${styles[`plan--${variant}`]}
          ${customClass}
        `}
      >
        <div className={styles.plan__heading}>
          <label htmlFor="plan">Avançado</label>
        </div>

        <div className={styles.plan__content}>
          <div>
            <p className={styles.plan__highlightText}>Personalizado</p>

            <p className={styles.plan__text}>{text || CUSTOM_PLAN.text}</p>

            <List items={details || CUSTOM_PLAN.details} />
          </div>

          {children}

          <div>
            {hasCustomButton && (
              <Button
                variant="primary"
                id="plan-custom-button"
                isLoading={isLoading}
                onClick={() => {
                  setIsLoading(true)
                  onClickCTA && onClickCTA(CUSTOM_PLAN)
                  setIsLoading(false)
                }}
                {...(!onClickCTA && customPlanUrlProps)}
              >
                Descubra o Plano ideal
              </Button>
            )}

            {hasWhatsappButton && (
              <ButtonWhatsapp
                id="plans-whatsapp-button"
                variant={whatsappVariant}
                customClass={styles.plan__whatsappButton}
              >
                Falar com especialista
              </ButtonWhatsapp>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
