import { useRouter } from 'next/navigation'

import { useProposalContext } from '@/context/ProposalContext'

import { SOURCE_URL_LABEL } from '@/constants/api'
import { E2E_FLOW_DATA, STEPS } from '@/constants/steps'
import { API_FLOW_LABEL } from '@/constants'

const ROUTES_OUTSIDE_FORM = {
  score: '/resultado-consulta'
}

export const useNavigation = () => {
  const { setIsLoading, setLeadData } = useProposalContext()

  const router = useRouter()

  const getStepRoute = (stepName) => {
    const flowData = JSON.parse(sessionStorage.getItem(API_FLOW_LABEL)) || {}

    const FLOW_STEPS = STEPS[flowData.flow] || {}
    const E2E_STEPS = STEPS[E2E_FLOW_DATA.flow]

    if (ROUTES_OUTSIDE_FORM[stepName]) return ROUTES_OUTSIDE_FORM[stepName]
    if (FLOW_STEPS[stepName]) return `/formulario/${FLOW_STEPS[stepName]}`
    if (E2E_STEPS[stepName]) return `/formulario/${E2E_STEPS[stepName]}`

    return null
  }

  const redirectStep = (stepName) => {
    const stepRoute = getStepRoute(stepName)

    if (!stepRoute) {
      goBackHome()
      return
    }

    router.push(stepRoute)
  }

  const goBackHome = async () => {
    setIsLoading(true)

    const sourceURL = sessionStorage.getItem(SOURCE_URL_LABEL)
    const initialRoute = (sourceURL && JSON.parse(sourceURL)) || '/'

    setLeadData({})

    router.replace(initialRoute)
  }

  return {
    redirectStep,
    goBackHome
  }
}
