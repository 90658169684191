const CLIENT_DATA = {
  icon: 'equifax-alert',
  title: 'Identificamos que você já possui um plano contratado.',
  details: [
    <>
      Este é um canal de vendas, e você já é cliente da Equifax BoaVista. Para
      dúvidas ou suporte, ligue no <b>3003-0101</b>.
    </>
  ]
}

export const PAYWALL_TEXTS = {
  valid: {
    icon: 'equifax-alert',
    title: 'Este é um canal exclusivo para vendas.'
  },
  unavailable: {
    icon: 'equifax-user-error',
    title: 'Infelizmente não podemos seguir com sua contratação.',
    details: [
      'Alguns dos motivos que podem causar o impedimento são:',
      'Sua empresa utiliza nossos produtos por meio de um de nossos parceiros (Associações Comerciais ou Outros). Neste caso, pedimos que entre em contato diretamente com eles.',
      'Pendências com a Equifax BoaVista.',
      'Irregularidades no CNPJ.',
      'O CNPJ não está ativo na Receita Federal.',
      'O ramo de atuação da empresa não é válido para contratação (CNAE).'
    ]
  },
  client: CLIENT_DATA,
  'lead-duplicated': CLIENT_DATA
}
