'use client'

import { useEffect } from 'react'

import { useProposalContext } from '@/context/ProposalContext'

import styles from './Action.module.scss'

export const Action = ({ isVisible, children }) => {
  const { removeToast } = useProposalContext()

  useEffect(() => {
    if (isVisible) {
      removeToast()
    }
  }, [isVisible])

  return (
    <div
      className={`${styles.action} ${isVisible && styles['action--visible']}`}
    >
      {children}
    </div>
  )
}
