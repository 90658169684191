import _formatters from '@/utils/formatters'

import styles from './UnitValues.module.scss'

export const UnitValues = ({ label, unitValuesLabel, unitValues }) => {
  return (
    <>
      <table className={styles.unitValues__table}>
        <thead>
          <tr>
            <th>{label}</th>
            <th>Valor</th>
          </tr>
        </thead>

        <tbody>
          {Object.entries(unitValues).map(([name, value], i) => (
            <tr key={`plan-unitValues-${unitValuesLabel}--${i}`}>
              <td>
                {unitValuesLabel} {name}
              </td>

              <td>{_formatters.toCurrency(value)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}
