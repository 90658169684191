import { useState } from 'react'

export const usePlan = ({ plan, action, setExpandedPlans, onClickCard }) => {
  const [loadingButtonID, setLoadingButtonID] = useState()

  const isNotCustom = plan.plan_price !== 1

  const isOrganic = NEXT_PUBLIC_BUILD_TYPE === 'organic' // eslint-disable-line no-undef
  const planBaseRoute = isOrganic
    ? '/planos/formulario/dados-basicos/'
    : '/formulario/dados-basicos/'

  const onClickAction = () => {
    if (action?.onClick) {
      action.onClick()
      return
    }

    onExpandDetails()
  }

  const onExpandDetails = () => {
    setExpandedPlans((prev) => ({
      ...prev,
      [plan.plan_price]: !prev[plan.plan_price]
    }))
  }

  const onSelectPlan = () => {
    if (plan.plan_price !== 1) {
      onClickCard && onClickCard(plan.plan_price)
    }
  }

  return {
    planBaseRoute,
    loadingButtonID,
    isNotCustom,
    onClickAction,
    onSelectPlan,
    setLoadingButtonID
  }
}
