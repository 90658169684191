/* eslint-disable no-undef */

import { axiosInstance } from './axios'

export const createLead = async ({ path, flow, payload }) => {
  const route = `/${path}/softlead/?flow-key=${flow}`

  return await axiosInstance.post(route, payload)
}

export const submitStep = async ({ path, flow, step, payload }) => {
  const route = `/${path}/${step}/?flow-key=${flow}`

  return await axiosInstance.post(route, payload)
}

export const getStepData = async ({ path, flow, step }) => {
  const route = `/${path}/${step}/?flow-key=${flow}`

  return await axiosInstance.get(route)
}

export const sendAction = async (lead_id, route, payload = {}) => {
  return await axiosInstance.post(`/leads/${lead_id}/${route}`, payload)
}

export const updateLead = async (lead_id, route, payload = {}) => {
  return await axiosInstance.put(`/leads/${lead_id}/${route}`, payload)
}
