'use client'

import { useEffect } from 'react'

import { customPageView } from '@/utils/trackingGTM'

import { useProposalContext } from '@/context/ProposalContext'

import { Button, ProgressBar, SkeletonLoader, Toast } from '..'

import styles from './Step.module.scss'

export const Step = ({
  title,
  subtitle,
  children,
  customClass,
  customLoader,
  progress,
  actions = [],
  tagName = 'div'
}) => {
  const { toastData, isLoading } = useProposalContext()

  const CustomTag = tagName

  useEffect(() => {
    customPageView()
  }, [])

  return (
    <>
      {isLoading ? (
        <>{customLoader || <SkeletonLoader.Step />}</>
      ) : (
        <>
          {progress && <ProgressBar value={progress} />}

          <Toast {...toastData} />

          <CustomTag className={styles.step}>
            <div className={`${styles.step__content} ${customClass}`}>
              {title && (
                <header className={styles.step__header}>
                  <h1 className={styles.step__title}>{title}</h1>
                  <h2 className={styles.step__subtitle}>{subtitle}</h2>
                </header>
              )}

              {children}
            </div>

            <div className={styles.controllers}>
              {actions.map((data) => (
                <Button key={data.id} {...data}>
                  {data.text}
                </Button>
              ))}
            </div>
          </CustomTag>
        </>
      )}
    </>
  )
}
