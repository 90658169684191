'use client'

import { Button, ButtonWhatsapp, Input, Modal } from '@/components'

import _validators from '@/utils/validators'

import { usePaywall } from './usePaywall'

import { Guideline } from './components'

import styles from './Paywall.module.scss'

const cnpjValidation = {
  required: 'Campo obrigatório',
  validate: (value) => _validators.cnpj(value) || 'CNPJ inválido'
}

export const Paywall = () => {
  const {
    control,
    title,
    guidelineType,
    isValid,
    onClickWhatsapp,
    onChangeGuideline,
    closeModal
  } = usePaywall()

  return (
    <Modal section="paywall" title={title} onClose={closeModal}>
      {guidelineType === 'valid' && (
        <form className={styles.paywall__form}>
          <Input.Cnpj
            id="cnpj"
            name="cnpj"
            label="CNPJ da minha empresa"
            placeholder="XX.XXX.XXX/0001-XX"
            control={control}
            rules={cnpjValidation}
          />

          <ButtonWhatsapp
            hasIcon={false}
            disabled={!isValid}
            id="paywall-whatsapp-button"
            aria-label="Falar com especialista"
            onClickWhatsapp={onClickWhatsapp}
          >
            Contratar pelo WhatsApp
          </ButtonWhatsapp>
        </form>
      )}

      <Guideline guidelineType={guidelineType} closeModal={closeModal} />

      {guidelineType !== 'valid' && (
        <Button
          id="paywall-back-button"
          className={styles.paywall__button}
          onClick={() => onChangeGuideline('valid')}
        >
          Voltar
        </Button>
      )}
    </Modal>
  )
}
