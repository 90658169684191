'use client'

import { useState } from 'react'
import { usePathname } from 'next/navigation'

import { useProposalContext } from '@/context/ProposalContext'

import { get } from '@/services/general'

import { newRelicNotifyError } from '@/utils/trackingNewRelic'

import {
  WHATSAPP_BASE_URL,
  WHATSAPP_PHONE_NUMBERS,
  WHATSAPP_URL_PARAMS,
  WHATSAPP_URL_PARAMS_PARSER
} from '@/constants/whatsapp'

export const useWhatsapp = ({ onClickWhatsapp } = {}) => {
  const [isLoading, setIsLoading] = useState(false)

  const { isPaywallOpen, setIsPaywallOpen } = useProposalContext()

  const pathName = usePathname()
  const isFormRoute = pathName.includes('formulario')

  const getWhatsappNumber = async () => {
    try {
      const {
        data: { phone_number }
      } = await get({
        path: `/chat-status?origin=${origin}`,
        config: {
          timeout: 3000,
          headers: { 'app-key': api.default.secondaryAppKey } // eslint-disable-line no-undef
        }
      })

      return phone_number
    } catch (error) {
      newRelicNotifyError('Getting whatsapp redirect url')
    }
  }

  const mountWhatsappURL = (phoneNumber) => {
    if (phoneNumber) {
      const urlParams =
        WHATSAPP_URL_PARAMS_PARSER[phoneNumber] ?? WHATSAPP_URL_PARAMS
      return `${WHATSAPP_BASE_URL}${phoneNumber}${urlParams}`
    }

    const origin = NEXT_PUBLIC_BUILD_TYPE ?? 'paid' // eslint-disable-line no-undef
    return `${WHATSAPP_BASE_URL}${WHATSAPP_PHONE_NUMBERS[origin]}${WHATSAPP_URL_PARAMS}`
  }

  const redirectToWhatsApp = (phoneNumber) => {
    const whatsappURL = mountWhatsappURL(phoneNumber)

    const isMobileDevice =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )

    if (isMobileDevice) {
      window.location.href = whatsappURL
      return
    }

    window?.open(whatsappURL, '_blank')
  }

  const goToWhatsapp = async () => {
    if (isFormRoute) {
      redirectToWhatsApp()
      return
    }

    setIsLoading(true)

    const phoneNumber = await getWhatsappNumber()

    setIsLoading(false)
    redirectToWhatsApp(phoneNumber)
  }

  const onClickWhatsappButton = async () => {
    if (isFormRoute) {
      redirectToWhatsApp()
      return
    }

    if (!isPaywallOpen) {
      setIsPaywallOpen(true)
      return
    }

    setIsLoading(true)

    const shouldGoToWhatsapp =
      (onClickWhatsapp && (await onClickWhatsapp())) ?? true

    if (!shouldGoToWhatsapp) return

    goToWhatsapp()
  }

  return {
    isLoading,
    setIsLoading,
    goToWhatsapp,
    onClickWhatsappButton
  }
}
