import isClient from '@equifax-ui/utils/browser/isClient'

import { SOURCE_URL_LABEL } from '@/constants/api'

import { newRelicNotifyError } from './trackingNewRelic'

export const getSourceUrl = async () => {
  if (!isClient()) return

  try {
    const valueFromStorage = sessionStorage.getItem(SOURCE_URL_LABEL)

    if (!valueFromStorage) return undefined

    return JSON.parse(valueFromStorage)
  } catch (error) {
    console.error(error)
    newRelicNotifyError(`Error getSourceUrl: ${error}`)
    return undefined
  }
}

const getUrlWithoutToken = () => {
  const url = window.location.href

  if (!url.includes('origem')) return url

  const urlObj = new URL(url)
  const params = new URLSearchParams(urlObj.search)

  const originParameter = params.get('origem') || ''

  return `${urlObj.origin}/${originParameter}`
}

export const setSourceUrl = async () => {
  if (!isClient()) return

  try {
    const valueFromStorage = sessionStorage.getItem(SOURCE_URL_LABEL)

    if (!valueFromStorage || JSON.parse(valueFromStorage) === '') {
      const sourceUrl = getUrlWithoutToken()

      sessionStorage.setItem(SOURCE_URL_LABEL, JSON.stringify(sourceUrl))

      window?.dataLayer.push({ sourceUrl })
    }
  } catch (error) {
    console.error(error)
    newRelicNotifyError(`Error setSourceUrl: ${error}`)
  }
}
