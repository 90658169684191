import { useState } from 'react'

import { ACERTA_DATA, DEFINE_DATA } from './constants'

export const useServicesModal = ({ type }) => {
  const indexFromCard = type === 'acerta' ? 0 : 1

  const [currentTab, setCurrentTab] = useState(type)
  const [index, setIndex] = useState(indexFromCard)

  const dataToRender = currentTab === 'acerta' ? ACERTA_DATA : DEFINE_DATA

  const [activeFilter, setActiveFilter] = useState(dataToRender.filters[0])

  const headerTitle = [{ ...ACERTA_DATA.tabInfos }, { ...DEFINE_DATA.tabInfos }]

  const onChangeTab = (i, tab) => {
    setCurrentTab(tab)
    setIndex(i)
    setActiveFilter(dataToRender.filters[0])
  }

  return {
    activeFilter,
    dataToRender,
    setActiveFilter,
    header: {
      onClick: onChangeTab,
      index,
      data: headerTitle
    }
  }
}
