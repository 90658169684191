'use client'

import { useEffect, useState } from 'react'

import Image from 'next/image'

import useMediaQuery from '@equifax-ui/hooks/useMediaQuery'
import isClient from '@equifax-ui/utils/browser/isClient'

import { SOURCE_URL_LABEL } from '@/constants/api'

import Logo from '/public/icons/logo.svg'

import { ButtonWhatsapp, Container } from '@/components'

import { Categories, MenuToggler } from './components'

import { WorldwideSitesToggler } from '..'

import styles from './TopContent.module.scss'

export const TopContent = ({
  selectedCategory,
  isMenuOpen,
  isWorldwideSitesVisible,
  setIsMenuOpen,
  setSelectedCategory,
  setIsWorldwideSitesVisible
}) => {
  const [sourceURL, setSourceURL] = useState('/')

  const isDesktop = useMediaQuery('screen and (min-width: 1024px)')
  const isOrganic = process.env.NEXT_PUBLIC_BUILD_TYPE === 'organic'

  useEffect(() => {
    const sourceURL =
      (isClient() && JSON.parse(sessionStorage.getItem(SOURCE_URL_LABEL))) ||
      '/'

    setSourceURL(sourceURL)
  }, [])

  return (
    <Container classname={styles.topContent}>
      <MenuToggler isOpen={isMenuOpen} setIsOpen={setIsMenuOpen} />

      <a
        id="header-logo-image"
        rel="noreferrer"
        href={sourceURL}
        className={styles.topContent__logo}
      >
        <Image
          priority
          width={168}
          height={24}
          src={Logo}
          alt="Equifax BoaVista - Logo"
        />
      </a>

      <Categories
        activeCategory={selectedCategory}
        onClick={setSelectedCategory}
      />

      {!isOrganic && (
        <ButtonWhatsapp
          variant="link"
          id="header-whatsapp-button"
          customClass={styles.topContent__whatsappButton}
        >
          {isDesktop && 'Contrate pelo WhatsApp'}
        </ButtonWhatsapp>
      )}

      {isDesktop && (
        <WorldwideSitesToggler
          arrowPosition="right"
          isWorldwideSitesVisible={isWorldwideSitesVisible}
          onClick={() => setIsWorldwideSitesVisible((prev) => !prev)}
        />
      )}
    </Container>
  )
}
