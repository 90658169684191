'use client'

import Image from 'next/image'

import { useProposalContext } from '@/context/ProposalContext'

import Logo from '/public/icons/logo.svg'

import { Container, If, ButtonWhatsapp, ShoppingCart } from '..'

import styles from './Header.module.scss'

export const Header = () => {
  const { isFeedbackStep } = useProposalContext()

  return (
    <header className={styles.header}>
      <Container classname={styles.header__container}>
        <div className={styles.header__image}>
          <Image
            priority
            src={Logo}
            alt="Equifax BoaVista - Logo"
            style={{
              width: '100%',
              height: 'auto'
            }}
          />
        </div>

        <div className={styles.header__buttons}>
          <ShoppingCart />

          <If condition={!isFeedbackStep}>
            <ButtonWhatsapp
              variant="none"
              id="formHeader-whatsapp-button"
              aria-label="Falar com especialista no whatsapp"
            />
          </If>
        </div>
      </Container>
    </header>
  )
}
