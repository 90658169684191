import { Card, HighlightTitle, List } from '@/components'

import { PAYWALL_TEXTS } from './constants'

import { useGuideline } from './useGuideline'

import styles from './Guideline.module.scss'

export const Guideline = ({ guidelineType, closeModal }) => {
  const { defaultDetails } = useGuideline({ closeModal })

  const details =
    guidelineType === 'valid'
      ? defaultDetails
      : PAYWALL_TEXTS[guidelineType].details

  return (
    <Card
      withBorder
      size="small"
      bgColor="gray"
      customClass={`${styles.guidelines} ${guidelineType !== 'valid' && styles['guidelines--invalid']}`}
    >
      <HighlightTitle
        size="small"
        icon={PAYWALL_TEXTS[guidelineType].icon}
        text={<b>{PAYWALL_TEXTS[guidelineType].title}</b>}
      />

      <List items={details} />
    </Card>
  )
}
